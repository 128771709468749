﻿window.Rewards = {
    undoReviewTransaction: function undoReviewTransaction(transactionId) {
        let buttonsId = "b_" + transactionId;
        let submittedId = "s_" + transactionId;
        let request = "/Rewards/UndoReviewTransaction/";
        let rewardTransactionModel = {
            ID: transactionId
        };

        $.ajax({
            url: request,      // The URL to which the request is sent
            type: 'PUT',                  // The HTTP method to use for the request
            contentType: 'application/json', // The content type of the request
            data: JSON.stringify(rewardTransactionModel),    // The data to send, converted to a JSON string
            success: function (response) {
                document.getElementById(submittedId).classList.add('visually-hidden');
                document.getElementById(buttonsId).classList.remove('visually-hidden');
                let toast = document.getElementById('success-toast');
                toast.classList.add("show");
                setTimeout(hideToasts, 2000)
            },
            error: function (jqXHR, textStatus, errorThrown) {
                document.getElementById(buttonsId).classList.remove('visually-hidden');
                let toast = document.getElementById('failure-toast');
                toast.classList.add("show");
                setTimeout(hideToasts, 2000)
            }
        });
    },

    reviewTransaction: function reviewTransaction(transactionId, approve) {
        let buttonsId = "b_" + transactionId;
        let submittedId = "s_" + transactionId;
        let submittedTextId = "st_" + transactionId;
        let request = "/Rewards/ReviewTransaction/";
        let rewardTransactionModel = {
            ID: transactionId,
            IsApproved: approve
        };

        $.ajax({
            url: request,      // The URL to which the request is sent
            type: 'PUT',                  // The HTTP method to use for the request
            contentType: 'application/json', // The content type of the request
            data: JSON.stringify(rewardTransactionModel),    // The data to send, converted to a JSON string
            success: function (response) {
                document.getElementById(buttonsId).classList.add('visually-hidden');
                document.getElementById(submittedId).classList.remove('visually-hidden');
                document.getElementById(submittedTextId).innerText = approve ? "Approved" : "Denied";
                let toast = document.getElementById('success-toast');
                toast.classList.add("show");
                setTimeout(hideToasts, 2000)
            },
            error: function (jqXHR, textStatus, errorThrown) {
                document.getElementById(buttonsId).classList.remove('visually-hidden');
                let toast = document.getElementById('failure-toast');
                toast.classList.add("show");
                setTimeout(hideToasts, 2000)
            }
        });
    }
}
